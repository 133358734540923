import React from 'react'
import '../App.css'


const ReservedNames = () => {
    return (
    <><div className="MainBody">
         <div className="UMTitle">reserved names</div>
    </div>
    </>
    )
}
 export default ReservedNames