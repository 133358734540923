import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';

const timestampToDateString = (timestamp) => {
  const tsInMilliseconds = timestamp * 1000;
  const date = new Date(tsInMilliseconds);
  return date.toLocaleDateString();
};

const StackedBarChart = () => {
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({
    ios: [],
    web: [],
    android: [],
    chart_labels: [],
    dvms: [],
    combined_labels: []
  });

  const [isFetchingDone, setIsFetchingDone] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsFetchingDone(false);
        const e = await window.nostr.signEvent({
          created_at: Math.floor(new Date().getTime() / 1000),
          kind: 10000222,
          tags: [],
          content: 'i want in',
        });

        const response = await fetch('https://admin.primal.net/api2', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            event_from_user: e,
            request: ['home', {}],
          }),
        });

        if (response.ok) {
          const data = await response.json();
          const chartLabels = data.chart_labels;
          const dvmsLabels = data.dvms.usage_graph.labels;
          
          // Combine labels and sort them in ascending order
          const combinedLabels = Array.from(new Set([...chartLabels, ...dvmsLabels])).sort((a, b) => a - b);

          // Create a function to align data with the combined labels
          const alignDataWithLabels = (labels, data) => {
            const dataMap = labels.reduce((acc, label, index) => {
              acc[label] = data[index];
              return acc;
            }, {});
            return combinedLabels.map(label => dataMap[label] !== undefined ? dataMap[label] : 0);
          };

          // Align all datasets with the combined labels
          const alignedIos = alignDataWithLabels(chartLabels, data.chart_values.ios);
          const alignedWeb = alignDataWithLabels(chartLabels, data.chart_values.web);
          const alignedAndroid = alignDataWithLabels(chartLabels, data.chart_values.android);
          const alignedDvms = alignDataWithLabels(dvmsLabels, data.dvms.usage_graph.values);

          setChartData({
            ios: alignedIos,
            web: alignedWeb,
            android: alignedAndroid,
            dvms: alignedDvms,
            combined_labels: combinedLabels
          });
        } else {
          console.error('Failed to fetch data from the API');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsFetchingDone(true);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (isFetchingDone && chartRef.current) {
      const ctx = chartRef.current.getContext('2d');
      const timestamps = chartData.combined_labels.map(timestampToDateString);

      new Chart(ctx, {
        type: 'bar',
        data: {
          labels: timestamps,
          datasets: [
            {
              label: 'DVMs',
              data: chartData.dvms,
              backgroundColor: '#6C003B'
            },
            {
              label: 'Web',
              data: chartData.web,
              backgroundColor: '#B71874'
            },
            {
              label: 'iOS',
              data: chartData.ios,
              backgroundColor: '#FA3D3D',
            },
            {
              label: 'Android',
              data: chartData.android,
              backgroundColor: '#FFA030',
            },
          ],
        },
        options: {
          scales: {
            x: {
              display: false,
              stacked: true,
            },
            y: {
              display: false,
              stacked: true,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: true,
              mode: 'index',
              intersect: false,
              callbacks: {
                label: function(context) {
                  let label = context.dataset.label || '';

                  if (label) {
                    label += ': ';
                  }
                  if (context.parsed.y !== null) {
                    label += context.parsed.y;
                  }
                  return label;
                }
              }
            }
          },
          maintainAspectRatio: false,
        },
      });
    }
  }, [chartData, isFetchingDone]);

  return (
    <div>
      <canvas ref={chartRef} style={{ height: '200px', width: '90%' }} />
    </div>
  );
};

export default StackedBarChart;
