import React from 'react';
import '../App.css';
import { useState, useEffect } from 'react';
import HomeChartData from './Graphs';
import ActivationsChart from './ActivationGraph';
import SubsChart from './SubsChart';
import { hexToBytes } from '@noble/hashes/utils';
import { bech32 } from '@scure/base';
import DefaultAvatar from '../Images/default avatar.svg'

function formatNumberWithCommas(number) {
  return number ? number.toLocaleString() : "0";
}

const npubEncode = (hex) => {
  const Bech32MaxSize = 5000;
  const words = bech32.toWords(hexToBytes(hex));
  return bech32.encode('npub', words, Bech32MaxSize);
};

function HomeData() {
    const [data, setData] = useState(null);
    const [primalAccountBalance, setPrimalAccountBalance] = useState(0);
    const [walletTotalsStrikeAccountBalance, setWalletTotalsStrikeAccountBalance] = useState(0);
    const [avatars, setAvatars] = useState({});
    const [loading, setLoading] = useState(true);
    const [avatarsLoading, setAvatarsLoading] = useState(true); // New state for avatars loading

    useEffect(() => {
      const fetchData = async () => {
        try {
          const signEvent = await window.nostr.signEvent({
            created_at: Math.floor(new Date().getTime() / 1000),
            kind: 10000222,
            tags: [],
            content: 'i want in',
          });

          const fetchHomeData = async (event) => {
            const response = await fetch('https://admin.primal.net/api2', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                event_from_user: event,
                request: ['home', {}],
              }),
            });
            return response.json();
          };

          const fetchWalletTotals = async (event) => {
            const response = await fetch('https://admin.primal.net/api2', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                event_from_user: event,
                request: ['wallet_totals', { kyc_level: 2 , offset: 0, limit: 50 }],
              }),
            });
            return response.json();
          };

          const [homeData, walletTotals] = await Promise.all([
            fetchHomeData(signEvent),
            fetchWalletTotals(signEvent)
          ]);

          if (homeData && !homeData.error) {
            setData(homeData);
          } else {
            console.error('Error fetching home data:', homeData.error);
          }

          if (walletTotals && walletTotals.all_primal_wallets_balance !== undefined) {
            setPrimalAccountBalance(walletTotals.all_primal_wallets_balance);
          }

          if (walletTotals && walletTotals.strike_account_balance !== undefined) {
            setWalletTotalsStrikeAccountBalance(walletTotals.strike_account_balance);
          }

          setLoading(false);
        } catch (error) {
          console.error('Error fetching API data:', error);
          setLoading(false);
        }
      };

      fetchData();
    }, []);

    const fetchAvatar = async (pubKey) => {
      const payload = JSON.stringify(["user_infos", { "pubkeys": [pubKey] }]);
      try {
        const response = await fetch("https://dev.primal.net/api", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: payload,
        });
    
        if (response.ok) {
          const data = await response.json();
          if (data && Array.isArray(data) && data.length > 0) {
            const userData = JSON.parse(data[0].content);
            return {
              avatar: userData.picture || null, // Use the `picture` if present, else `null`
              name: userData.name || userData.display_name || 'Unknown', // Prioritize `name`, then `display_name`, then 'Unknown'
            };
          }
        }
      } catch (error) {
        console.error("Error fetching avatar:", error);
      }
      return { avatar: null, name: 'Unknown' }; // Default case if fetch fails or no data
    };
    
    
  
    // Fetch avatars and names when the data is available
    useEffect(() => {
      if (data?.subscriptions?.latest_subscriptions) {
        const fetchAllAvatars = async () => {
          const avatarsData = {};
          // Slice to get only the latest 10 subscriptions
          const latestSubscribers = data.subscriptions.latest_subscriptions.slice(-10);
    
          for (const subscriber of latestSubscribers) {
            const { avatar, name } = await fetchAvatar(subscriber.receiver_pubkey);
            avatarsData[subscriber.receiver_pubkey] = { avatar, name };
          }
          setAvatars(avatarsData);
          setAvatarsLoading(false); // Set to false when avatars are fully loaded
        };
        fetchAllAvatars();
      }
    }, [data]);
  
    if (loading) {
      return <div className="loader"><span></span></div>;
    }
  
    if (!data) {
      return <div>No data available</div>;
    }

    const {
      authenticated,
      authenticated_versions,
      authenticated_users,
      wallet,
      subscriptions,
      dvms
    } = data;

    const numberOfActivatedSubscriptions = subscriptions?.active_subscriptions || 0;
    const numberOfActivatedSubscriptions24h = subscriptions?.new_subscriptions_24h || 0;
    const subAnnualValue = numberOfActivatedSubscriptions * 5.50 * 12; // Example calculation, adjust as needed

    const androidAppData = authenticated_versions['android'];
    const iOSAppData = authenticated_versions['ios'];
    const webAppData = authenticated_versions['web'];

    const balanceCheck = walletTotalsStrikeAccountBalance - primalAccountBalance;

    function getSubscriptionLabel(months) {
      switch (months) {
        case 1:
          return '1m';
        case 3:
          return '3m';
        case 12:
          return '12m';
        case -1:
          return 'LGD';
        default:
          return ''; // fallback for unexpected values
      }
    }

    return (
      <>
        <div className="Container1">
          <div className="GraphContainerTwo">
            <div className="GroupContainerA">
              <div className="ChartTitleDAUA">Active Subs</div>
              <div className="Group588">
                <p>{formatNumberWithCommas(numberOfActivatedSubscriptions)}</p>
              </div>
            </div>
            <div className="chartplaceA">
              <SubsChart />
            </div>
          </div>
          <div className="GroupContainerOneHome">
            <div className="ContainerOneHome">
              <div className="AuthenticatedHome">
                <div className="GroupContainer">
                  <div className="AuthenticatedHomeTitle">New Subs 24h</div>
                  <div className="Group408">
                    <p>{formatNumberWithCommas(numberOfActivatedSubscriptions24h)}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="ContainerTwoHome">
              <div className="WebAppDataHome">
                <div className="GroupContainer1">
                  <div className="WebAppHomeTitle2">Sub Revenue</div>
                  <div className="Group715">
                    <p>${formatNumberWithCommas(subAnnualValue)}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="GraphContainerThree">
    <h3 className="latest-subscribers-title">Latest Subscribers</h3>
    {avatarsLoading ? ( // Show loader while avatars are being fetched
      <div className="loader"><span></span></div>
    ) : (
      <div className="latest-subscribers-list">
        {/* Two columns for displaying subscribers */}
        {[0, 1].map((colIndex) => (
          <div className="column" key={colIndex}>
            {/* Map through 5 items per column */}
            {Array.from({ length: 5 }).map((_, index) => {
              // Calculate the actual subscriber index in the data
              const subscriberIndex = colIndex * 5 + index;
              const subscriber = data.subscriptions.latest_subscriptions.slice(-10)?.[subscriberIndex];
              const avatarData = subscriber ? avatars[subscriber.receiver_pubkey] || {} : {};
              const npub = subscriber ? npubEncode(subscriber.receiver_pubkey) : '';
              const subscriptionLabel = subscriber ? getSubscriptionLabel(subscriber.months) : '';

              return (
                <div className="subscriber" key={subscriber?.receiver_pubkey || `placeholder-${colIndex}-${index}`}>
                  {subscriber ? (
                    // Render actual subscriber data if available
                    <a href={`https://primal.net/p/${npub}`} target="_blank" rel="noopener noreferrer" className="LatestSubscriberLink">
                      <img
                        src={avatarData.avatar ? avatarData.avatar : DefaultAvatar}
                        alt={avatarData.name || 'Unknown'}
                        className="avatar"
                      />
                      <span className="subscriber-name">{avatarData.name || 'Unknown'}</span>
                    </a>
                  ) : (
                    // Render placeholder data
                    <div className="LatestSubscriberLink">
                      <img
                        src={DefaultAvatar}
                        alt="Placeholder"
                        className="avatar"
                      />
                      <span className="subscriber-name"></span>
                    </div>
                  )}
                  <span className="subscriber-time">• {subscriptionLabel}</span>
                </div>
              );
            })}
          </div>
        ))}
      </div>
    )}
  </div>
          </div>
        </div>
    
        <div className="Container1">
          <div className="GraphContainerTwo">
            <div className="GroupContainerA">
              <div className="ChartTitleDAUA">Active Wallets</div>
              <div className="Group588">
                <p>{formatNumberWithCommas(wallet.number_of_activated_wallets || 0)}</p>
              </div>
            </div>
            <div className="chartplaceA">
              <ActivationsChart />
            </div>
          </div>
          <div className="GroupContainerOneHome">
            <div className="ContainerOneHome">
              <div className="AuthenticatedHome">
                <div className="GroupContainer">
                  <div className="AuthenticatedHomeTitle">Activations 24h</div>
                  <div className="Group408">
                    <p>{formatNumberWithCommas(wallet.number_of_activated_wallets_24h || 0)}</p>
                  </div>
                </div>
              </div>
              <div className="UniqueIPHome">
                <div className="GroupContainer">
                  <div className="UniqueIPHomeTitle">Transactions 24h</div>
                  <div className="Group410">
                    <p>{formatNumberWithCommas(wallet.number_of_transactions_24h || 0)}</p>
                  </div>
                </div>
              </div>
              <div className="NostrUniqueHome">
                <div className="GroupContainer">
                  <div className="NostrUniqueHomeTitle">Tx Sats 24h</div>
                  <div className="Group412">
                    <p>{formatNumberWithCommas(wallet.transaction_volume_24h * 100000000)}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="ContainerTwoHome">
              <div className="WebAppDataHome">
                <div className="GroupContainer1">
                  <div className="WebAppHomeTitle1">Total Balance</div>
                  <div className="Group714">
                    <p>{wallet.strike_account_balance || 0}</p>
                    <p className={`balanceCheck ${balanceCheck < 0 ? 'negative' : 'positive'}`}>
                      {formatNumberWithCommas(balanceCheck * 100000000)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="IOSHome">
                <div className="GroupContainer">
                  <div className="IOSHomeTitle">Total Transactions</div>
                  <div className="Group414">
                    <p>{formatNumberWithCommas(wallet.number_of_transactions || 0)}</p>
                  </div>
                </div>
              </div>
              <div className="AndroidHome">
                <div className="GroupContainer">
                  <div className="AndroidHomeTitle">Total Tx (BTC)</div>
                  <div className="Group416">
                    <p>{Number(wallet.transaction_volume || 0).toFixed(8)}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    
        <div className="Container1">
          <div className="GraphContainerOne">
            <div className="GroupContainer">
              <div className="ChartTitleDAU">Primal DAU</div>
              <div className="Group500">
                <p>{formatNumberWithCommas(authenticated.DAU || 0)}</p>
              </div>
            </div>
            <div className="chartplace">
              <HomeChartData />
            </div>
          </div>
          <div className="GroupContainerOneHome">
            <div className="ContainerOneHome">
              <div className="AuthenticatedHome">
                <div className="GroupContainer">
                  <div className="AuthenticatedHomeTitle">Primal WAU</div>
                  <div className="Group408">
                    <p>{formatNumberWithCommas(authenticated.WAU || 0)}</p>
                  </div>
                </div>
              </div>
              <div className="UniqueIPHome">
                <div className="GroupContainer">
                  <div className="UniqueIPHomeTitle">Primal MAU</div>
                  <div className="Group410">
                    <p>{formatNumberWithCommas(authenticated.MAU || 0)}</p>
                  </div>
                </div>
              </div>
              <div className="NostrUniqueHome">
                <div className="GroupContainer">
                  <div className="AndroidHomeTitle">DVM DAU</div>
                  <div className="Group414">
                    <p>{formatNumberWithCommas(dvms?.per_period?.DAU || 0)}</p>
                  </div>
                  <div className="dvmline"></div>
                </div>
              </div>
            </div>
            <div className="ContainerTwoHome">
              <div className="WebAppDataHome">
                <div className="GroupContainer">
                  <div className="WebAppHomeTitle">Web DAU</div>
                  <div className="Group414">
                    <p>{formatNumberWithCommas(webAppData.DAU || 0)}</p>
                  </div>
                  <div className="webline"></div>
                </div>
              </div>
              <div className="IOSHome">
                <div className="GroupContainer">
                  <div className="IOSHomeTitle">iOS DAU</div>
                  <div className="Group414">
                    <p>{formatNumberWithCommas(iOSAppData.DAU || 0)}</p>
                  </div>
                  <div className="iosline"></div>
                </div>
              </div>
              <div className="AndroidHome">
                <div className="GroupContainer">
                  <div className="AndroidHomeTitle">Android DAU</div>
                  <div className="Group416">
                    <p>{formatNumberWithCommas(androidAppData.DAU || 0)}</p>
                    <div className="androidline"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

const Home = () => {
    return (
        <div className="MainBody">
          <div className="HomeData">
            <HomeData />
          </div>
        </div>
    )
}
 export default Home