import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { hexToBytes } from '@noble/hashes/utils';
import { bech32 } from '@scure/base';
import '../App.css';
import ReservedNamesIcon from '../Images/ReservedNames.svg';
import PromoCodesIcon from '../Images/PromoCodes.svg';
import DefaultAvatar from '../Images/default avatar.svg';

const PremiumSubs = () => {
    const history = useHistory();
    const [subscriptionData, setSubscriptionData] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [avatars, setAvatars] = useState({});
    const [avatarsLoading, setAvatarsLoading] = useState(true);
    const [loading, setLoading] = useState(true);

    const navigateToReservedNames = () => {
        history.push('/premium/reserved_names');
    };

    const navigateToPromoCodes = () => {
        history.push('/premium/promo_codes');
    };

    const calculateSubAnnualValue = (activeSubs) => activeSubs * 5.5 * 12;

    const calculateSubRevenueProjection = (subAnnualValue, newSubs24h) =>
        subAnnualValue + newSubs24h * 365 * 12 * 5.5;

    const npubEncode = (hex) => {
        const words = bech32.toWords(hexToBytes(hex));
        return bech32.encode('npub', words);
    };

    // Fetch avatars for all users in one API call
    const fetchAvatars = async (pubKeys) => {
        const payload = JSON.stringify(["user_infos", { "pubkeys": pubKeys }]);
        try {
            const response = await fetch("https://dev.primal.net/api", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: payload,
            });

            if (response.ok) {
                const data = await response.json();
                const avatarsData = {};
                if (data && Array.isArray(data)) {
                    data.forEach(user => {
                        const userData = JSON.parse(user.content);
                        avatarsData[user.pubkey] = {
                            avatar: userData.picture,
                        };
                    });
                }
                setAvatars(avatarsData);
            }
        } catch (error) {
            console.error("Error fetching avatars:", error);
        }
        setAvatarsLoading(false); // Set to false when avatars are fully loaded
    };

    // Fetch subscription data and avatars
    useEffect(() => {
        const fetchSubscriptionData = async () => {
            try {
                const e = await window.nostr.signEvent({
                    created_at: Math.floor(new Date().getTime() / 1000),
                    kind: 10000222,
                    tags: [],
                    content: 'i want in',
                });

                const response = await fetch('https://admin.primal.net/api2', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        event_from_user: e,
                        request: ['subscriptions', { limit: 500, offset: 0 }],
                    }),
                });

                const data = await response.json();
                if (data && !data.error) {
                    setSubscriptionData(data);
                    setTableData(data.subscriptions); // Display all subscription items

                    // Get all unique pubKeys for fetching avatars in one request
                    const pubKeys = data.subscriptions.map(subscriber => subscriber.receiver_pubkey);
                    fetchAvatars(pubKeys); // Fetch avatars for all pubKeys at once
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching API data:', error);
                setLoading(false);
            }
        };

        fetchSubscriptionData();
    }, []);

    // Helper function to format timestamp
    const formatDate = (timestamp) => {
        const date = new Date(timestamp * 1000);
        return date.toLocaleString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        });
    };

    // Helper function to get SKU label based on months
    const getSkuLabel = (months) => {
        switch (months) {
            case 1:
                return "1M";
            case 3:
                return "3M";
            case 12:
                return "12M";
            case -1:
                return "LGD";
            default:
                return months;
        }
    };

    return (
        <>
            <div className="MainBody">
                <div className="PremiumHeader">
                    <div className="UMTitle">subscriptions</div>
                    <div className="PremiumPageButtons">
                        <button className="ReservedNamesButton" onClick={navigateToReservedNames}>
                            <img src={ReservedNamesIcon} alt="Reserved Names"></img>
                        </button>
                        <button className="PromoCodesButton" onClick={navigateToPromoCodes}>
                            <img src={PromoCodesIcon} alt="Promo Codes"></img>
                        </button>
                    </div>
                </div>
                <div className="PremiumCardZone">
                    {loading ? (
                        <div>Loading...</div>
                    ) : (
                        <div className="PremiumCardsContainer">
                            <div className="PremiumCard">
                                <div className="PremiumCardTitle">Active Subs</div>
                                <div className="PremiumCardValue">
                                    {subscriptionData?.active_subscriptions ?? 'N/A'}
                                </div>
                            </div>
                            <div className="PremiumCard">
                                <div className="PremiumCardTitle">Sub Revenue</div>
                                <div className="PremiumCardValue">
                                    {subscriptionData?.subscriptions_revenue_usd != null
                                        ? `$${Number(parseFloat(subscriptionData.subscriptions_revenue_usd).toFixed(2)).toLocaleString('en-US')}`
                                        : 'N/A'}
                                </div>
                            </div>
                            <div className="PremiumCard">
                                <div className="PremiumCardTitle">Sub Annual Value</div>
                                <div className="PremiumCardValue">
                                    {subscriptionData?.active_subscriptions != null
                                        ? `$${calculateSubAnnualValue(
                                              subscriptionData.active_subscriptions
                                          ).toLocaleString('en-US')}`
                                        : 'N/A'}
                                </div>
                            </div>
                            <div className="PremiumCard">
                                <div className="PremiumCardTitle">New Subs 24h</div>
                                <div className="PremiumCardValue">
                                    {subscriptionData?.new_subscriptions_24h ?? 'N/A'}
                                </div>
                            </div>
                            <div className="PremiumCard">
                                <div className="PremiumCardTitle">Sub Rev. Projection</div>
                                <div className="PremiumCardValue">
                                    {subscriptionData?.active_subscriptions != null &&
                                    subscriptionData?.new_subscriptions_24h != null
                                        ? `$${calculateSubRevenueProjection(
                                              calculateSubAnnualValue(
                                                  subscriptionData.active_subscriptions
                                              ),
                                              subscriptionData.new_subscriptions_24h
                                          ).toFixed(2).toLocaleString('en-US')}`
                                        : 'N/A'}
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                {/* Table for subscription details */}
                <div className="SubscriptionTable">
                    <table>
                        <thead>
                            <tr>
                                <th></th> {/* Empty header for avatar */}
                                <th>Name</th>
                                <th>SKU</th>
                                <th>Revenue</th>
                                <th>Revenue Type</th>
                                <th>Origin</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map((item, index) => {
                                const npub = npubEncode(item.receiver_pubkey);
                                const skuLabel = getSkuLabel(item.months); // Get SKU label based on months
                                return (
                                    <tr key={index}>
                                        <td>
                                            <a href={`https://primal.net/p/${npub}`} target="_blank" rel="noopener noreferrer">
                                                <img
                                                    src={
                                                        avatars[item.receiver_pubkey]?.avatar || DefaultAvatar
                                                    }
                                                    alt="Avatar"
                                                    className="avatar-img"
                                                />
                                            </a>
                                        </td>
                                        <td>
                                            <a href={`https://primal.net/p/${npub}`} target="_blank" rel="noopener noreferrer" className="SubName">
                                                {item.name || 'Unknown'}
                                            </a>
                                        </td>
                                        <td>{skuLabel}</td>
                                        <td>{item.revenue_usd != null ? `$${parseFloat(item.revenue_usd).toFixed(2)}` : 'N/A'}</td>
                                        <td>{item.revenue_type ?? 'N/A'}</td>
                                        <td>{item.origin ?? 'N/A'}</td>
                                        <td>{formatDate(item.purchased_at)}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default PremiumSubs;
