import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import defaultPicture from '../Images/default avatar.svg';
import GreenArrow from '../Images/green_arrow.svg';
import RedArrow from '../Images/red_arrow.svg';
import freezeWallet from '../Images/freezeWallet.svg';
import changePubkey from '../Images/ChangePubkey.svg';
import unfreezeWallet from '../Images/unfreezeWallet.svg';
import disableFreezingImg from '../Images/DisableFreezing.svg'; // Import the new button image
import setMaxBalance from '../Images/setMaxBalance.svg';
import setNewPubkeyImg from '../Images/setNewPubkey.svg';
import cancelButton from '../Images/CancelButton.svg';
import eraseWallet from '../Images/eraseWallet.svg';
import { useToastContext } from './Toast';
import { hexToBytes } from '@noble/hashes/utils';
import { bech32 } from '@scure/base';
import '../App.css';

function UserPage() {
  const { pubkey } = useParams();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [profilePicture, setProfilePicture] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [isFrozen, setIsFrozen] = useState(false);
  const [maxBalanceInput, setMaxBalanceInput] = useState('');
  const [maxBalanceInputFocused, setMaxBalanceInputFocused] = useState(false);
  const [activeTab, setActiveTab] = useState('total');
  const [showChangePubkeyModal, setShowChangePubkeyModal] = useState(false);
  const [newPubkey, setNewPubkey] = useState('');
  const { sendWarning, sendSuccess } = useToastContext();
  const history = useHistory();

  const Bech32MaxSize = 5000;

  const npubEncode = (hex) => {
    return encodeBytes('npub', hexToBytes(hex));
  };

  const npubDecode = (nip19) => {
    try {
      const { prefix, words } = bech32.decode(nip19, Bech32MaxSize);
      const data = new Uint8Array(bech32.fromWords(words));
      if (prefix !== 'npub') throw new Error('Invalid prefix');
      return bytesToHex(data);
    } catch (error) {
      throw new Error('Error decoding npub: ' + error.message);
    }
  };

  function bytesToHex(bytes) {
    return Array.from(bytes)
      .map(byte => byte.toString(16).padStart(2, '0'))
      .join('');
  }

  function encodeBytes(prefix, bytes) {
    const words = bech32.toWords(bytes);
    return bech32.encode(prefix, words, Bech32MaxSize);
  }

  useEffect(() => {
    async function fetchUserData() {
      try {
        await new Promise((resolve) => setTimeout(resolve, 500));
        const e = await window.nostr.signEvent({
          created_at: Math.floor(new Date().getTime() / 1000),
          kind: 10000222,
          tags: [],
          content: 'i want in',
        });

        const response = await fetch('https://admin.primal.net/api2', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            event_from_user: e,
            request: ['wallet_account', { pubkey }],
            additional_data: ['number_of_transactions', 'number_of_incoming_transactions', 'number_of_outgoing_transactions'],
          }),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const userData = await response.json();
        setUserData(userData);
        setLoading(false);

        fetchProfilePicture(pubkey);

        if (userData.transactions) {
          setTransactions(userData.transactions);
        }

        setIsFrozen(userData.freezed);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }

    fetchUserData();
  }, [pubkey]);

  async function fetchProfilePicture(selectedPubkey) {
    await new Promise((resolve) => setTimeout(resolve, 500));
    const payload = JSON.stringify(['user_infos', { "pubkeys": [selectedPubkey] }]);

    try {
      const response = await fetch('https://dev.primal.net/api', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: payload,
      });

      if (response.ok) {
        const data = await response.json();
        if (data && Array.isArray(data) && data.length > 0) {
          const userData = JSON.parse(data[0].content);
          const profilePictureUrl = userData.picture;
          setProfilePicture(profilePictureUrl);
        }
      } else {
        console.error('Failed to fetch profile picture:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching profile picture:', error);
    }
  }

  const handleFreezeButtonClick = async () => {
    const confirmation = window.confirm(`Are you sure you want to freeze ${userData.pubkey_resolved.nostr_lightning_address}?`);
    if (!confirmation) return;

    try {
      const e = await window.nostr.signEvent({
        created_at: Math.floor(new Date().getTime() / 1000),
        kind: 10000222,
        tags: [],
        content: 'freeze wallet',
      });

      const response = await fetch('https://admin.primal.net/api2', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          event_from_user: e,
          request: ['wallet_account_freeze', { pubkey, freezed: true }],
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      setIsFrozen(true);
    } catch (error) {
      console.error('Error freezing wallet:', error);
    }
  };

  const handleUnfreezeButtonClick = async () => {
    const confirmation = window.confirm(`Are you sure you want to unfreeze ${userData.pubkey_resolved.nostr_lightning_address}?`);
    if (!confirmation) return;

    try {
      const e = await window.nostr.signEvent({
        created_at: Math.floor(new Date().getTime() / 1000),
        kind: 10000222,
        tags: [],
        content: 'unfreeze wallet',
      });

      const response = await fetch('https://admin.primal.net/api2', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          event_from_user: e,
          request: ['wallet_account_freeze', { pubkey, freezed: false }],
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      setIsFrozen(false);
    } catch (error) {
      console.error('Error unfreezing wallet:', error);
    }
  };

  const handleDisableFreezingClick = async () => {
    const confirmation = window.confirm(`Are you sure you want to disable freezing for ${userData.pubkey_resolved.nostr_lightning_address}?`);
    if (!confirmation) return;

    try {
      const e = await window.nostr.signEvent({
        created_at: Math.floor(new Date().getTime() / 1000),
        kind: 10000222,
        tags: [],
        content: 'disable freezing',
      });

      const response = await fetch('https://admin.primal.net/api2', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          event_from_user: e,
          request: ['wallet_account_disable_freezing', { pubkey, disable: true }],
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      sendSuccess(`Disabled Freezing for ${userData.name || 'the user'}`);
    } catch (error) {
      console.error('Error disabling freezing:', error);
      sendWarning('Failed to disable freezing');
    }
  };

  const handleSetMaxBalance = async (e) => {
    e.preventDefault();

    const newMaxBalance = parseFloat(maxBalanceInput) / 100000000;

    if (isNaN(newMaxBalance)) {
      return;
    }
    const formattedMaxBalance =(newMaxBalance * 100000000).toLocaleString();

    const confirmation = window.confirm(
        `Are you sure you want to set the max balance to ${formattedMaxBalance} sats?`
      );

    if (!confirmation) {
      return;
    }

    try {
      const e = await window.nostr.signEvent({
        created_at: Math.floor(new Date().getTime() / 1000),
        kind: 10000222,
        tags: [],
        content: 'set max balance',
      });

      const response = await fetch('https://admin.primal.net/api2', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          event_from_user: e,
          request: ['wallet_account_set_max_balance', { pubkey, balance: newMaxBalance.toFixed(8) }],
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      setUserData((prevUserData) => ({
        ...prevUserData,
        max_balance: newMaxBalance,
      }));

      setMaxBalanceInput((newMaxBalance * 100000000).toLocaleString());

      sendSuccess('Max balance changed successfully');
    } catch (error) {
      console.error('Error changing max balance:', error);
      sendWarning('Failed to change max balance');
    }
  };

  useEffect(() => {
    if (userData) {
      setMaxBalanceInput((userData.max_balance * 100000000).toLocaleString());
    }
  }, [userData]);

  const handleEraseWalletClick = async () => {
    const confirmation = window.confirm(`Are you sure you want to erase ${userData.pubkey_resolved.nostr_lightning_address}?`);
    if (!confirmation) return;

    try {
      const e = await window.nostr.signEvent({
        created_at: Math.floor(new Date().getTime() / 1000),
        kind: 10000222,
        tags: [],
        content: 'erase wallet',
      });

      const response = await fetch('https://admin.primal.net/api2', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          event_from_user: e,
          request: ['wallet_account_erase', { pubkey, comment: 'Erasing wallet' }],
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      console.log('Wallet erased successfully');
      history.goBack();
    } catch (error) {
      console.error('Error erasing wallet:', error);
    }
  };

  const profilePictureStyle = {
    backgroundImage: `url(${profilePicture || defaultPicture})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '120px',
    height: '120px',
  };

  function formatTimestamp(timestamp) {
    const dateOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };

    const timeOptions = {
      hour: '2-digit',
      minute: '2-digit',
    };

    const formattedDate = new Date(timestamp).toLocaleString('en-US', dateOptions);
    const formattedTime = new Date(timestamp).toLocaleString('en-US', timeOptions);

    return `${formattedDate} at ${formattedTime}`;
  }

  const filteredTransactions = transactions.filter((transaction) => {
    if (activeTab === 'incoming') {
      return transaction.amount_btc >= 0;
    } else if (activeTab === 'outgoing') {
      return transaction.amount_btc < 0;
    }
    return true;
  });

  const handleOpenChangePubkeyModal = () => {
    setShowChangePubkeyModal(true);
  };
  
  const handleCloseChangePubkeyModal = () => {
    setShowChangePubkeyModal(false);
  };
  
  const handleChangePubkeySubmit = async (e) => {
    e.preventDefault();
  
    if (!newPubkey) {
      sendWarning('Please enter a new public key');
      return;
    }
  
    try {
      const hexNewPubkey = npubDecode(newPubkey);
  
      const event = await window.nostr.signEvent({
        created_at: Math.floor(new Date().getTime() / 1000),
        kind: 10000222,
        tags: [],
        content: 'change pubkey request',
      });
  
      const response = await fetch('https://admin.primal.net/api2', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          event_from_user: event,
          request: ['wallet_account_change_pubkey', { pubkey, new_pubkey: hexNewPubkey, force: true }],
        }),
      });
  
      const result = await response.json();
      if (response.ok && result.success) {
        sendSuccess('Public key changed successfully');
        setNewPubkey('');
        handleCloseChangePubkeyModal();
        history.push(`/user/${hexNewPubkey}`);
      } else {
        sendWarning(result.message || 'Failed to change public key');
      }
    } catch (error) {
      console.error('Error changing public key:', error);
      sendWarning(error.message || 'Failed to change public key');
    }
  };

  return (
    <div className="MainBody">
      {loading && <div className="loader"><span></span></div>}
      {!loading && (
        <>
          <div className="ContainerOne">
            <div className="UserPicture">
              {profilePicture ? (
                <div className="UserPicture" style={profilePictureStyle}></div>
              ) : (
                <img className="UserPicture" src={defaultPicture} alt="Default Icon" />
              )}
            </div>
            <div className="UserInfo">
              <div className="Group600">
                <p>KYC Name</p>
                <p>Verified Email</p>
                <p>Primal Lightning Address</p>
                <p>Nostr Lightning Address</p>
                <p>Wallet Created On</p>
                <p>Country</p>
                <p>State</p>
              </div>
              <div className="Group601">
                <p>{userData.name || '[null]'}</p>
                <p>{userData.email || '[null]'}</p>
                <p>{userData.primal_lightning_address}</p>
                <p>{userData.pubkey_resolved.lightning_address || '[null]'}</p>
                <p>{userData.created_at}</p>
                <p>{userData.country || '[null]'}</p>
                <p>{userData.state || '[null]'}</p>
              </div>
            </div>
            <div className="WalletBalance">
                <div className="WalletBalanceTitle">Wallet Balance (sats)</div>
                <div className="Group602">{(userData.balance * 100000000).toLocaleString()}</div>
                <div className="MaxBalance">Max wallet balance: {(userData.max_balance * 100000000).toLocaleString()}</div>
            </div>
          </div>
          <div className="FreezeDeleteButtons">
          <button className="deleteWallet" onClick={handleDisableFreezingClick}>
            <img src={disableFreezingImg} alt="Disable Freezing" />
          </button>
            {isFrozen ? (
              <button className="freezeWallet1" onClick={handleUnfreezeButtonClick}>
                <img src={unfreezeWallet} alt="Unfreeze Wallet" />
              </button>
            ) : (
              <button className="freezeWallet1" onClick={handleFreezeButtonClick}>
                <img src={freezeWallet} alt="Freeze Wallet" />
              </button>
            )}
            <button className="changePubkey" onClick={handleOpenChangePubkeyModal}>
              <img src={changePubkey} alt="Change Pubkey"/>
            </button>
            <button className="eraseWallet" onClick={handleEraseWalletClick}>
              <img src={eraseWallet} alt="Erase Wallet"/>
            </button>
            <form className="setMaxBalanceForm" onSubmit={handleSetMaxBalance}>
                <label className="label">
                    Set Max Balance (sats)
                    <input
                        type="text"
                        name="setMaxBalance"
                        className={`inputSetMaxBalance ${maxBalanceInputFocused ? 'focused' : ''}`}
                        value={maxBalanceInputFocused ? maxBalanceInput.replace(/,/g, '') : maxBalanceInput}
                        onChange={(e) => {
                            const newValue = e.target.value.replace(/,/g, '');
                            setMaxBalanceInput(newValue);
                        }}
                        onFocus={() => {
                            setMaxBalanceInputFocused(true);
                            setMaxBalanceInput(maxBalanceInput.replace(/,/g, ''));
                        }}
                        onBlur={() => {
                            setMaxBalanceInputFocused(false);
                        }}
                    />
                </label>
                <button className="setMaxBalance" onClick={handleSetMaxBalance}>
                    <img src={setMaxBalance} alt="Set Max Balance" />
                </button>
            </form>
          </div>
          <div className="TransactionTable">
            <div className="TransactionTabs">
                <button
                className={`TabButton ${activeTab === 'total' ? 'ActiveTab' : ''}`}
                onClick={() => setActiveTab('total')}
                >
                <span>{userData.number_of_transactions.toLocaleString()}</span><p>Total Transactions</p>
                </button>
                <button
                className={`TabButton ${activeTab === 'incoming' ? 'ActiveTab' : ''}`}
                onClick={() => setActiveTab('incoming')}
                >
                <span>{userData.number_of_incoming_transactions.toLocaleString()}</span><p>Incoming</p>
                </button>
                <button
                className={`TabButton ${activeTab === 'outgoing' ? 'ActiveTab' : ''}`}
                onClick={() => setActiveTab('outgoing')}
                >
                <span>{userData.number_of_outgoing_transactions.toLocaleString()}</span><p>Outgoing</p>
                </button>
            </div>
            <table className="transaction_table">
              <thead>
                <tr className="table_header">
                  <th></th>
                  <th>Amount</th>
                  <th>To/From</th>
                  <th>Transaction Date & Time</th>
                  <th>Notes</th>
                </tr>
              </thead>
              <tbody>
                {filteredTransactions.map((transaction, index) => (
                    <tr className="table_row" key={index}>
                    <td>
                        {transaction.amount_btc >= 0 ? (
                        <img src={GreenArrow} alt="Green Arrow" />
                        ) : (
                        <img src={RedArrow} alt="Red Arrow" />
                        )}
                    </td>
                    <td>{(transaction.amount_btc * 100000000).toLocaleString()}</td>
                    <td>
                        {transaction.pubkey_2_resolved
                        ? transaction.pubkey_2_resolved.display_name || '[null]'
                        : '[null]'}
                    </td>
                    <td>{formatTimestamp(transaction.created_at)}</td>
                    <td>{transaction.note}</td>
                    </tr>
                ))}
                </tbody>
            </table>
          </div>
          {showChangePubkeyModal && (
            <div className="modalBackground">
              <div className="modalContainer">
                <h2 className="modalTitle">Change Wallet Pubkey</h2>
                <div className="inputModalContainer">
                  <label className="inputModalLabel" htmlFor="newPubkey">New Pubkey:</label>
                  <input
                    id="newPubkey"
                    type="text"
                    value={newPubkey}
                    onChange={(e) => setNewPubkey(e.target.value)}
                    placeholder="Enter new public key"
                    className="inputModalField"
                  />
                </div>
                <form onSubmit={handleChangePubkeySubmit} className="modalButtons">
                <button className="freezeWallet1" type="button" onClick={handleCloseChangePubkeyModal}>
                    <img src={cancelButton} alt="Cancel"/>
                  </button>
                  <button className="freezeWallet1" type="submit">
                    <img src={setNewPubkeyImg} alt="Set New Pubkey"/>
                  </button>
                </form>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default UserPage;
